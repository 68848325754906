<template>
  <v-container fluid>
    <div class="pb-2 d-flex">
      <v-btn class="ma-1" @click.stop="getEstudios" color="primary" small>
        <v-icon small left>mdi-refresh</v-icon>
        Recargar
      </v-btn>
      <v-btn class="ma-1" to="/instalaciones" color="primary" small>
        <v-icon small left>mdi-solar-power-variant</v-icon>
        Control instalaciones
      </v-btn>
      <v-spacer></v-spacer>
    </div>

    <v-tabs v-model="menuTab" touchless>
      <v-tab :disabled="loading" href="#pagos-pendiente" >Pagos pendientes</v-tab >
      <v-tab :disabled="loading" href="#pagados">Pagados</v-tab>
      <v-tab :disabled="loading" href="#sin-pagos">Sin pagos</v-tab>
    </v-tabs>

    <v-card>
      <v-data-table
        :headers="pagosHeaders"
        :items="estudios"
        :loading="loading"
        :single-select="false"
        item-key="idEstudio"
        :calculate-widths="true"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [25, 50, -1],
        }"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="estudios"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.estado_actual.estado="{ item }">
          <status-chip :value="item.estado_actual?.estado" />
        </template>
        <template v-slot:item.estado_actual_int.estado="{ item }">
          <status-chip :value="item.estado_actual_int?.estado" />
        </template>
        <template v-slot:item.idEstudio="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="secondary"
                :to="`/detalles_estudio?idEstudio=${item.idEstudio}`"
                text
              >
                <v-icon v-if="item.isAutoestudio" left
                  >mdi-lightning-bolt</v-icon
                >
                {{ item.idEstudio }}</v-btn
              >
            </template>
            Detalles estudio {{ item.isAutoestudio ? "(Express)" : "" }}
          </v-tooltip>
        </template>
        <template v-slot:item.precioVenta="{ item }">
          <strong v-if="item?.precioVenta !== null">{{
            money.format(item?.precioVenta)
          }}</strong>
          <span v-else class="grey--text">-</span>
        </template>
        <template v-slot:item.precioCompra="{ item }">
          <strong v-if="item?.precioCompra !== null">{{
            money.format(item?.precioCompra)
          }}</strong>
          <span v-else class="grey--text">-</span>
        </template>

        <template v-slot:item.pagado="{ item }">
          <v-progress-circular
            :rotate="360"
            :size="38"
            :width="4"
            :value="item.pagado"
            color="success"
          >
            <h5>{{ item.pagado }}</h5>
          </v-progress-circular>
        </template>

        <template v-slot:item.financiado="{ item }">
          <v-chip
            v-if="item?.financiado"
            outlined
            label
            color="info"
          >
            <v-icon left>mdi-handshake</v-icon>
            Financiado
          </v-chip>
        </template>

        <template v-slot:item.controlPagos="{ item }">
          <v-btn @click.stop="pagosId = item.idEstudio" color="primary" small
            >Pagos</v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      max-width="1200"
      :value="pagosId"
      @input="(v) => (v == false ? (pagosId = null) : null)"
    >
      <v-card :key="pagosId" class="pa-4">
        <PagosInstalacion :idEstudio="pagosId" tipo="instalacion" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    PagosInstalacion: () =>
      import("@/modules/estudios/components/kanban/PagosInstalacion.vue"),
  },
  data() {
    return {
      menuTab: "pagos-pendiente",
      estudios: [],
      pagosId: null,
      loading: false,
      inlineFilters: {},
      pagosHeaders: [
        { text: "Estado", value: "estado_actual.estado", dataType: "select" },
        {
          text: "Estado interno",
          value: "estado_actual_int.estado",
          dataType: "select",
        },
        { text: "Nº Estudio", value: "idEstudio", align: "end" },
        { text: "Nombre estudio", value: "nombreProyecto" },
        { text: "Precio venta", value: "precioVenta" },
        { text: "Precio compra", value: "precioCompra" },
        { text: "Pagado", value: "pagado" },
        {
          text: "Financiado",
          value: "financiado",
          dataType: "bool",
        },
        { text: "Pagos", value: "controlPagos" },
      ]
        .filter((h) => {
          return (
            (h.sub == null || h.sub) &&
            (h.acceso == null || this.$root.acceso(h.acceso))
          );
        })
        .map((header) => {
          return {
            class: "text-no-wrap sticky-header",
            cellClass: "pa-2 text-no-wrap",
            filter: (value) =>
              perColumnFilter(
                value,
                this.inlineFilters[header.value],
                header.dataType
              ),
            dataType: "text",
            ...header,
          };
        }),
      money: Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
    };
  },
  methods: {
    async getEstudios() {
      this.loading = true;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/pagos`,
        params: {
          tab: this.menuTab,
        },
      });

      this.estudios = data;
      this.loading = false;
    },
  },
  watch: {
    menuTab: {
      handler() {
        this.estudios = [];
        this.getEstudios();
      },
      immediate: true,
    },
  },
};
</script>
